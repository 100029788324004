<template>
  <b-form method="POST" @submit.prevent="onSubmit" ref="tellemForm">
    <div>
      <input type="hidden" name="_token" :value="csrf">

      
      <b-form-rating v-model="value" variant="warning" class="mb-2 rating-no-border" size="lg" name="tellemRating" id="tellemRating"></b-form-rating>
      <p class="mt-2"></p>
      <b-form-text id="tellemText-help-block">
      Type your feedback or use the <i class="fa fa-light fa-microphone"></i> icon on your mobile device to use Voice to Text.
    </b-form-text>
      <b-form-textarea id="textarea-state" v-model="tellemText" :state="computeTellem" placeholder="Your Tellem message.." rows="3" name="tellemText"  @blur="tellemTextBlur=true" aria-describedby="tellemText-help-block"></b-form-textarea">
      <b-form-invalid-feedback :state="computeTellem">
        Your Tellem must have at least 4 words.
      </b-form-invalid-feedback>
      
      <b-form-input v-model="emailAddress" name="email" @blur="validateEmail" required placeholder="Please enter your email"></b-form-input>
      <b-form-input v-model="confirmEmailAddress" name="email" @blur="validateConfirmEmail" required placeholder="Please confirm your email"></b-form-input>
      <p v-if="errors.length">
          <span v-for="error in errors" class="ratingError"><strong>{{ error }}</strong><br/></span>
        </p>
      <b-form-text id="tellemText-help-block">
      To send a Tellem, you must first complete a one-time email registration.<br/>Your Tellem will always remain <strong>anonymous</strong> and this one-time registration is not linked to the Tellem you are sending.
    </b-form-text>
    </div>
    <button type="submit" class="btn btn-warning btn-lg">Send Tellem</button>
            <p class="consumer-disclaimer">By sending this Tellem, you agree to Tellem's <a href="/terms-of-use">Terms and Conditions</a>, and <a href="/privacy-policy">Privacy Policy.</a></p>
  </b-form>
</template>

<script>
  export default {
    data() {
      return {
        value: null,
        tellemText: '',
        emailAddress: '',
        confirmEmailAddress: '',
        tellemTextBlur: false,
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        reg2: /\b\w+\b.*\b\w+\b.*\b\w+\b.*\b\w+\b/,
        errors: []
      }
    },
    computed:{
      computeTellem(){
        return this.tellemTextBlur ? (this.tellemText != '' && this.reg2.test(this.tellemText)) ? true : false : null;
      }
    },
    methods: {
      validateEmail() {
        console.log(this.emailAddress);
          this.errors = []
          if(!this.reg.test(this.emailAddress)){
              this.errors.push("Please enter a valid email address.");
              return false;
          } else {
              return true;
          }
      },
      validateConfirmEmail() {
        console.log(this.confirmEmailAddress);
          this.errors = []
          if(!this.reg.test(this.confirmEmailAddress)){
              this.errors.push("Please enter a valid email address.");
              return false;
          } else {
            if(this.confirmEmailAddress != this.emailAddress){
              this.errors.push("The email addresses do not match.");
              return false;
            }
            else{
              return true;
            }
          }
      },
      onSubmit(){
        this.errors = []
        console.log(this.validateEmail());
        if(this.value && this.validateEmail() && this.validateConfirmEmail()){ 
          let tellemReview = {
            tellemRating: this.value
          }
          this.$el.submit();
        }
        else{
          if(!this.value){
            this.errors.push("Rating is required.")
            return;
          }
        }
      }
    }
  }
</script>