<template>
  <b-form method="POST" @submit.prevent="onSubmit" ref="tellemForm">
    <div>
      <input type="hidden" name="_token" :value="csrf">
        <p v-if="errors.length">
          <span v-for="error in errors" class="ratingError"><strong>{{ error }}</strong><br/></span>
        </p>
      <b-form-rating v-model="value" variant="warning" class="mb-2" size="lg" name="tellemRating" id="tellemRating"></b-form-rating>
      <p class="mt-2"></p>
      <b-form-text id="tellemText-help-block">
      Type your feedback or use the <i class="fa fa-light fa-microphone"></i> icon on your mobile device to use Voice to Text.
    </b-form-text>
      <b-form-textarea id="textarea-state" v-model="tellemText" :state="computeTellem" placeholder="Your Tellem message.." rows="3" name="tellemText" style="font-family:Arial, FontAwesome" @blur="tellemTextBlur=true" aria-describedby="tellemText-help-block"></b-form-textarea">
      <b-form-invalid-feedback :state="computeTellem">
        Your Tellem must have at least 4 words.
      </b-form-invalid-feedback>
    </div>
    <button type="submit" class="btn btn-warning btn-lg">Send Tellem</button>
            <p class="consumer-disclaimer">By sending this Tellem, you agree to Tellem's <a href="/terms-of-use">Terms and Conditions</a>, and <a href="/privacy-policy">Privacy Policy.</a></p>
  </b-form>
</template>

<script>
  export default {
    data() {
      return {
        value: null,
        tellemText: '',
        tellemTextBlur: false,
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        reg: /\b\w+\b.*\b\w+\b.*\b\w+\b.*\b\w+\b/,
        errors: []
      }
    },
    computed:{
      computeTellem(){
        return this.tellemTextBlur ? (this.tellemText != '' && this.reg.test(this.tellemText)) ? true : false : null;
      }
    },
    methods: {
      onSubmit(){
        this.errors = []
        if(this.value && this.computeTellem){ 
          let tellemReview = {
            tellemRating: this.value
          }
          this.$el.submit();
        }
        else{
          if(!this.value){
            this.errors.push("Rating is required.")
            return;
          }
        }
      }
    }
  }
</script>