var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.numPages, function (i) {
      return _c(
        "pdf",
        {
          key: i,
          staticStyle: { width: "100%", margin: "20px auto" },
          attrs: {
            src: _vm.pdfdata,
            id: i,
            page: i,
            scale: _vm.scale,
            annotation: true,
            resize: true,
          },
          on: {
            "update:scale": function ($event) {
              _vm.scale = $event
            },
            "link-clicked": _vm.handle_pdf_link,
          },
        },
        [
          _c("template", { slot: "loading" }, [
            _vm._v("\n      loading content here...\n    "),
          ]),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }