var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      ref: "tellemForm",
      attrs: { method: "POST" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.csrf },
          }),
          _vm._v(" "),
          _c("b-form-rating", {
            staticClass: "mb-2 rating-no-border",
            attrs: {
              variant: "warning",
              size: "lg",
              name: "tellemRating",
              id: "tellemRating",
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "mt-2" }),
          _vm._v(" "),
          _c("b-form-text", { attrs: { id: "tellemText-help-block" } }, [
            _vm._v("\n    Type your feedback or use the "),
            _c("i", { staticClass: "fa fa-light fa-microphone" }),
            _vm._v(" icon on your mobile device to use Voice to Text.\n  "),
          ]),
          _vm._v(" "),
          _c("b-form-textarea", {
            attrs: {
              id: "textarea-state",
              state: _vm.computeTellem,
              placeholder: "Your Tellem message..",
              rows: "3",
              name: "tellemText",
              "aria-describedby": "tellemText-help-block",
            },
            on: {
              blur: function ($event) {
                _vm.tellemTextBlur = true
              },
            },
            model: {
              value: _vm.tellemText,
              callback: function ($$v) {
                _vm.tellemText = $$v
              },
              expression: "tellemText",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.computeTellem } },
            [_vm._v("\n      Your Tellem must have at least 4 words.\n    ")]
          ),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              name: "email",
              required: "",
              placeholder: "Please enter your email",
            },
            on: { blur: _vm.validateEmail },
            model: {
              value: _vm.emailAddress,
              callback: function ($$v) {
                _vm.emailAddress = $$v
              },
              expression: "emailAddress",
            },
          }),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              name: "email",
              required: "",
              placeholder: "Please confirm your email",
            },
            on: { blur: _vm.validateConfirmEmail },
            model: {
              value: _vm.confirmEmailAddress,
              callback: function ($$v) {
                _vm.confirmEmailAddress = $$v
              },
              expression: "confirmEmailAddress",
            },
          }),
          _vm._v(" "),
          _vm.errors.length
            ? _c(
                "p",
                _vm._l(_vm.errors, function (error) {
                  return _c("span", { staticClass: "ratingError" }, [
                    _c("strong", [_vm._v(_vm._s(error))]),
                    _c("br"),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-form-text", { attrs: { id: "tellemText-help-block" } }, [
            _vm._v(
              "\n    To send a Tellem, you must first complete a one-time email registration."
            ),
            _c("br"),
            _vm._v("Your Tellem will always remain "),
            _c("strong", [_vm._v("anonymous")]),
            _vm._v(
              " and this one-time registration is not linked to the Tellem you are sending.\n  "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-warning btn-lg", attrs: { type: "submit" } },
        [_vm._v("Send Tellem")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "consumer-disclaimer" }, [
        _vm._v("By sending this Tellem, you agree to Tellem's "),
        _c("a", { attrs: { href: "/terms-of-use" } }, [
          _vm._v("Terms and Conditions"),
        ]),
        _vm._v(", and "),
        _c("a", { attrs: { href: "/privacy-policy" } }, [
          _vm._v("Privacy Policy."),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }