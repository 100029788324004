<template>
  <div>
    <b-form-textarea id="textarea-state" v-model="tellemText" :state="tellemText.length >= 10" placeholder="Enter your reply" rows="3" name="tellemText" required></b-form-textarea>
    <br/>
    <div class="alert alert-info">We suggest that you include your name and title to emphasize the importance of your reply to the customer.</div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        tellemText: ''
      }
    }
  }
</script>