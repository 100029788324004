var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      ref: "tellemForm",
      attrs: { method: "POST", action: this.postRoute },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.csrfToken },
          }),
          _vm._v(" "),
          _c("b-form-text", { attrs: { id: "emailAddresses-help-block" } }, [
            _vm._v(
              "\n    Enter the email addresses below comma or semicolon separated.\n  "
            ),
          ]),
          _vm._v(" "),
          _c("b-form-textarea", {
            attrs: {
              id: "emailAddresses",
              state: _vm.verifyEmails,
              placeholder: "user1@mytellem.com, user2@mytellem.com",
              rows: "3",
              name: "emailAddresses",
              "aria-describedby": "emailAddresses-help-block",
            },
            on: {
              blur: function ($event) {
                _vm.verifyEmailsBlur = true
              },
            },
            model: {
              value: _vm.emailAddresses,
              callback: function ($$v) {
                _vm.emailAddresses = $$v
              },
              expression: "emailAddresses",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.verifyEmails } },
            [
              _vm._v(
                "\n      You must enter valid email addresses and they have to be comma or semi-colon seperated.\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-info btn-round", attrs: { type: "submit" } },
        [_vm._v("Invite Users Tellem")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }