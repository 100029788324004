var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-form-textarea", {
        attrs: {
          id: "textarea-state",
          state: _vm.tellemText.length >= 10,
          placeholder: "Enter your reply",
          rows: "3",
          name: "tellemText",
          required: "",
        },
        model: {
          value: _vm.tellemText,
          callback: function ($$v) {
            _vm.tellemText = $$v
          },
          expression: "tellemText",
        },
      }),
      _vm._v(" "),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }