<template>
  <b-form method="POST" @submit.prevent="onSubmit" ref="tellemForm" :action="this.postRoute">
    <div>
      <input type="hidden" name="_token" :value="csrfToken">   
      <b-form-text id="emailAddresses-help-block">
      Enter the email addresses below comma or semicolon separated.
    </b-form-text>
      <b-form-textarea id="emailAddresses" v-model="emailAddresses" :state="verifyEmails" placeholder="user1@mytellem.com, user2@mytellem.com" rows="3" name="emailAddresses" @blur="verifyEmailsBlur=true" aria-describedby="emailAddresses-help-block"></b-form-textarea">
      <b-form-invalid-feedback :state="verifyEmails">
        You must enter valid email addresses and they have to be comma or semi-colon seperated.
      </b-form-invalid-feedback>
    </div>
    <button type="submit" class="btn btn-info btn-round">Invite Users Tellem</button>
  </b-form>
</template>

<script>
  export default {
    props: ['postRoute','csrfToken'],
    data() {
      return {
        emailAddresses: '',
        verifyEmailsBlur: false,
        
        reg: /^(?:[^\s@]+@[^\s@]+[.][^\s@]+)[,;]*(?:\s+(?:[^\s@]+@[^\s@]+[.][^\s@]+)[,;]*)*$/,
        errors: []
      }
    },
    computed:{
      verifyEmails(){
        console.log(this.postRoute);
        return this.verifyEmailsBlur ? (this.emailAddresses != '' && this.reg.test(this.emailAddresses)) ? true : false : null;
      }
    },
    methods: {
      onSubmit(){
        this.errors = []
        if(this.verifyEmails){ 
          this.$el.submit();
        }
      }
    }
  }
</script>