<template>
  <b-form method="POST" @submit.prevent="onSubmit" ref="verifyTellemForm">
    <div>
      <input type="hidden" name="_token" :value="csrf">
        <p v-if="errors.length">
          <span v-for="error in errors" class="ratingError"><strong>{{ error }}</strong><br/></span>
        </p>
    <label for="password">Password for your Tellem Account</label>
    <b-form-input type="password" id="password" aria-describedby="password-help-block" :state="validation" v-model="password" name="password" @blur="passwordHasBlurred = true"></b-form-input>
     <b-form-invalid-feedback :state="validation">
      Your password must be 8-20 characters long, contain letters and numbers, and must not
      contain spaces, special characters, or emoji.
    </b-form-invalid-feedback>

    <label for="confirmPassword">Confirm Password for your Tellem Account</label>
    <b-form-input type="password" aria-describedby="confirmPassword-help-block" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" :state="confirmValidation" @blur="confirmPasswordHasBlurred = true"></b-form-input>
    <b-form-invalid-feedback :state="confirmValidation">
      You must enter the same password.
    </b-form-invalid-feedback>
      <p class="mt-2"></p>
    </div>
    <button type="submit" class="btn btn-warning btn-lg">Complete sign up</button>
            <p class="consumer-disclaimer">By signing up, you agree to Tellem's <a href="#">Terms and Conditions</a>, and <a href="#">Privacy Policy.</a></p>
  </b-form>
</template>

<script>
  export default {
    data() {
      return {
        value: null,
        passwordHasBlurred: false,
        confirmPasswordHasBlurred: false,
        password: '',
        confirmPassword: '',
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        reg: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).*$/,
        errors: []
      }
    },
    computed:{
      validation(){
        return this.passwordHasBlurred ? (this.password.length > 7 && this.reg.test(this.password)) ? true : false : null;
      },
      confirmValidation(){
        return this.confirmPasswordHasBlurred ? (this.password == this.confirmPassword && this.confirmPassword.length > 7) ? true : false : null;
      }
    },
    methods: {
      onSubmit(){
        if(this.validation && this.confirmValidation) this.$el.submit();
      }
    }
  }
</script>