var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      ref: "verifyTellemForm",
      attrs: { method: "POST" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.csrf },
          }),
          _vm._v(" "),
          _vm.errors.length
            ? _c(
                "p",
                _vm._l(_vm.errors, function (error) {
                  return _c("span", { staticClass: "ratingError" }, [
                    _c("strong", [_vm._v(_vm._s(error))]),
                    _c("br"),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("label", { attrs: { for: "password" } }, [
            _vm._v("Password for your Tellem Account"),
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              type: "password",
              id: "password",
              "aria-describedby": "password-help-block",
              state: _vm.validation,
              name: "password",
            },
            on: {
              blur: function ($event) {
                _vm.passwordHasBlurred = true
              },
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _vm._v(" "),
          _c("b-form-invalid-feedback", { attrs: { state: _vm.validation } }, [
            _vm._v(
              "\n    Your password must be 8-20 characters long, contain letters and numbers, and must not\n    contain spaces, special characters, or emoji.\n  "
            ),
          ]),
          _vm._v(" "),
          _c("label", { attrs: { for: "confirmPassword" } }, [
            _vm._v("Confirm Password for your Tellem Account"),
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              type: "password",
              "aria-describedby": "confirmPassword-help-block",
              id: "confirmPassword",
              name: "confirmPassword",
              state: _vm.confirmValidation,
            },
            on: {
              blur: function ($event) {
                _vm.confirmPasswordHasBlurred = true
              },
            },
            model: {
              value: _vm.confirmPassword,
              callback: function ($$v) {
                _vm.confirmPassword = $$v
              },
              expression: "confirmPassword",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.confirmValidation } },
            [_vm._v("\n    You must enter the same password.\n  ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mt-2" }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-warning btn-lg", attrs: { type: "submit" } },
        [_vm._v("Complete sign up")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "consumer-disclaimer" }, [
        _vm._v("By signing up, you agree to Tellem's "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("Terms and Conditions")]),
        _vm._v(", and "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("Privacy Policy.")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }